/* Components/Loading/Loading.css */
.loading-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.2em;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.5);
    img{
        width: 8%;
        @media only screen and (max-device-width: 480px){
            width: 30%;        
        }
    }
    .text-l{
        font-size: 0.8em;
        font-weight: 600;
    }

    .fade-in {
        opacity: 0;
        margin: 0 5px 0 0;
        animation: fadeIn 0.5s forwards;
    }
    
    .fade-in:nth-of-type(1) {
        animation-delay: 0s;
    }
    .fade-in:nth-of-type(2) {
        animation-delay: 0.5s;
    }
    .fade-in:nth-of-type(3) {
        animation-delay: 1s;
    }
    .text-l{
        display: flex;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
